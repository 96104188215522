// src/components/GlobalPopup.js
import React, { useState, useEffect } from "react";
import ContactUsFormHomePageFork from "./contact-us-form-home-page-fork";

export function GlobalPopup() {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const lastShown = localStorage.getItem("popupLastShown");
    const today = new Date().toDateString();
    const shouldShow = !lastShown || lastShown !== today;

    if (shouldShow) {
      const timer = setTimeout(() => {
        setIsVisible(true);
        localStorage.setItem("popupLastShown", today);
      }, 30000);

      return () => clearTimeout(timer);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        zIndex: 1000
      }}
    >
      <div
        style={{
          backgroundColor: "white",
          padding: "20px",
          borderRadius: "8px",
          boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
          maxWidth: "90%",
          maxHeight: "90%",
          overflow: "auto",
          position: "relative"
        }}
      >
        <button
          onClick={handleClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "none",
            border: "none",
            cursor: "pointer",
            padding: "5px",
            fontSize: "20px",
            fontWeight: "bold"
          }}
        >
          ×
        </button>
        <h2
          style={{ marginTop: "0", paddingRight: "16px" }}
          className="font-medium text-lg"
        >
          Book your<br></br> appointment today!
        </h2>
        <ContactUsFormHomePageFork />
      </div>
    </div>
  );
}
